@import url('https://fonts.googleapis.com/css2?family=Concert+One&display=swap');

.landing-page {
  /* background-image: url('../assets/study.png'); Adjust the path as needed */
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Concert One', sans-serif;
}
